(function ($, window, document, undefined) {

  'use strict';

  $(function () {

     

  });


})(jQuery, window, document);
